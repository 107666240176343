import React from "react";
import loadable from "@loadable/component";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import Goo from "gooey-react";

const Filter = loadable(() => import("./components/filter"));
function App() {
  const [data, setData] = React.useState(null);
  const [startYear, setStartYear] = React.useState(2021);
  const [endYear, setEndYear] = React.useState(22);
  const [letter, setLetter] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const [managerTitle, setManagerTitle] = React.useState('');


  // get list of all data files.
  // pick one.

  function UrlExists(url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
  }


  React.useEffect(() => {

    var baseName = 'data';
    var theSYear = 2021;
    var theEYear = 22;
    var theLetter = '';

    var filecheck = '';
    var filefound = true;
    var successfile = '';
    var theFile = '';
    successfile = baseName + theSYear.toString() + '-' + theEYear.toString() + '.json';

    var theFiles = [];

    do {
      filecheck = baseName + theSYear.toString() + '-' + theEYear.toString() + '.json';
      if (UrlExists(filecheck)) {
        successfile = filecheck;
        setStartYear(theSYear);
        setEndYear(theEYear);
        var found = false;
        setLetter(0);
        theLetter = 65;
        while (UrlExists(baseName + theSYear.toString() + '-' + theEYear.toString() + (theLetter>0 ? '_'+ String.fromCharCode(theLetter) : '')+ '.json')){
          if (!found){
          theFiles.push({ "file": filecheck, "startYear": theSYear, "endYear": theEYear , "letter" : 0});
          }
          found = true;
          setLetter(theLetter);
          successfile = baseName + theSYear.toString() + '-' + theEYear.toString() + (theLetter>0 ? '_'+ String.fromCharCode(theLetter) : '')+ '.json';
          theFiles.push({ "file": successfile, "startYear": theSYear, "endYear": theEYear, "letter":  theLetter}); 
          theLetter++;
        }
        if (!found){
          theFiles.push({ "file": filecheck, "startYear": theSYear, "endYear": theEYear , "letter" : 0});
        }  
          theSYear = theSYear + 1;
          theEYear = theEYear + 1;
          if (theLetter>0){
            theLetter--;
          }else{
            theLetter=0;
          }
        

      } else {
        theSYear = theSYear - 1;
        theEYear = theEYear - 1;
        filefound = false;
      }
    } while (filefound);
    theFiles.reverse();
    setFiles(theFiles);

    axios.get(successfile).then((response) => {
      setData(response.data);
    });
    if (theLetter!==0){
      theFile = './' + theSYear + '-' + theEYear + (theLetter>0 ? '_'+ String.fromCharCode(theLetter) : '')+'/manager.json';
    }else{
      theFile = './' + theSYear + '-' + theEYear + '/manager.json';
    }
    axios.get(theFile).then((response) => {
      setManagerTitle(response.data[0].name);
    });

  }, []); // [] = runs once

  const setSeason = (event) => {
    var successfile = event.target.value;
    var theFile = '';

    setStartYear(event.target.options[event.target.selectedIndex].getAttribute('data-startyear'));
    setEndYear(event.target.options[event.target.selectedIndex].getAttribute('data-endyear'));
    setLetter(event.target.options[event.target.selectedIndex].getAttribute('data-letter'));


    axios.get(successfile).then((response) => {
      setData(response.data);
    });

    if (event.target.options[event.target.selectedIndex].getAttribute('data-letter')!==0){
      theFile = './' + event.target.options[event.target.selectedIndex].getAttribute('data-startyear') + '-' + event.target.options[event.target.selectedIndex].getAttribute('data-endyear') +  '_'+String.fromCharCode(event.target.options[event.target.selectedIndex].getAttribute('data-letter'))+'/manager.json';
    }else{
      theFile = './' + event.target.options[event.target.selectedIndex].getAttribute('data-startyear') + '-' + event.target.options[event.target.selectedIndex].getAttribute('data-endyear') + '/manager.json';
    }

    axios.get(theFile).then((response) => {
      //alert(response.data[0]);
      setManagerTitle(response.data[0].name);
    });
  }

  if (!data) return <h3>Waiting</h3>;


  return (
    <>

      <div className="p-3 mb-4  rounded-10 claret">
        <div className="container-fluid py-5">
          <div className="row">
            <div className="col-sm col-md-3"><picture className="mb-5">

              <source srcSet={startYear + '-' + endYear + (letter>0 ? '_' + String.fromCharCode(letter) : '') + '/manager.webp'} type="image/webp" />
              <source srcSet={startYear + '-' + endYear + (letter>0 ? '_' + String.fromCharCode(letter) : '') + '/manager.png'} type="image/png" />
              <img
                width="175"
                height="219"
                alt={managerTitle}
                title={managerTitle}
                className="center-block"
                data-letter={letter}
              ></img>
            </picture></div>
            <div className="col-sm"><h1 className="display-5 fw-bold managerwatch">Manager watch {startYear}/{endYear}</h1>
              <p className="col-md-8 fs-4">Analysis of the managers seasonal league performance.</p>

              <p>Past Seasons : <select onChange={setSeason}>
                {
                  files.map((d) => (
                    <option data-letter={d.letter} key={d.file} value={d.file} data-startyear={d.startYear} data-endyear={d.endYear}>{d.startYear} / {d.endYear} {(d.letter>0 ? String.fromCharCode(d.letter) : '')}</option>
                  ))
                }
              </select></p>
            </div>



          </div>




        </div>
      </div>
      <div className="container py-4">



        <div className="container-fluid" style={{ clear: "both" }}>
          <div className="row">
            <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
              <Filter data={data} />
            </main>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="backgroundFooter">
          <div className="container text-center">
            <span className="text-white">&copy;2022 <a href="https://www.eskimo-nation.co.uk" style={{ color: "white" }}>eskimo-nation.co.uk</a></span>
          </div>
        </div>
        <Goo composite>
          <svg role="img" aria-label="Example of a gooey effect" style={{ left: 0 }}>
            <g>
              <circle
                cx="90%"
                cy="60%"
                r="24"
                fill="#801638"
                style={{
                  animation:
                    "1.5s ease-in-out 0s infinite alternate none running rise",
                }}
              ></circle>
              <rect
                x="0"
                y="0%"
                width="100%"
                height="27%"
                fill="#801638"
              ></rect>
            </g>
          </svg>
        </Goo>
      </footer>

    </>
  );
}
export default App;
